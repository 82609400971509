.number1 {
  background: #045fb4;
}
.number2 {
  background: #d43f3a;
}
.number3 {
  background: #df7401;
}
.number4 {
  background: #0b610b;
}
.number5 {
  background: #8a0886;
}
.number6 {
  background: #01a9db;
}
.number7 {
  background: #fe2e64;
}
.number8 {
  background: #4cae4c;
}
.number9 {
  background: #b43104;
}
.number10 {
  background: #084b8a;
}
.number11 {
  background: #8a0886;
}
.number12 {
  background: #f2592f;
}
.number13 {
  background: #e75228;
}
.number14 {
  background: #3104b4;
}
.number15 {
  background: #fe642e;
}
.number16 {
  background: #610b0b;
}
.number17 {
  background: #4c0b5f;
}
.number18 {
  background: #0b614b;
}
.number19 {
  background: #045fb4;
}
.number20 {
  background: #08298a;
}

.round {
  right: 8.5%;
}

.gistogramm {
  right: 10%;
  max-width: 135%;
}

.resultContainer {
  width: 100%;
  overflow: hidden;
  padding: 0 20px;
  display: flex;
  flex-wrap: nowrap;
}

@media screen and (max-width: 600px) {
  .resultContainer {
    flex-wrap: wrap;
  }
}
