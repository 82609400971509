.form {
  width: 100%;
  max-width: 600px;
  border: 1px solid #ccc;
  padding: 10px;
  margin-top: 5px;
}

.wrapper {
  display: flex;
  justify-content: center;
}
