.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.periodicTable {
  left: 0;
  right: 0;
  width: 70%;
  margin: 5% auto auto auto;
  z-index: 10001;
  transition: opacity 1s;
}

.periodicTable > div {
  margin-bottom: 2.5%;
}

.periodicTable > div > div {
  width: 5.5%;
  height: 0;
  display: inline-block;
  margin-left: -3px;
}

.periodicTable > div > div > div {
  cursor: pointer;
  line-height: 100%;
  color: black;
  border: 3px solid black;
  text-align: center;
  font-weight: bold;
  margin-top: -3px;
  width: 100%;
  height: 0;
  padding: 40% 0 54% 0;
}

.periodicTable > div > div > div:hover {
  background-color: rgba(255, 255, 255, 0.9);
}

.s > div {
  background-color: rgb(200, 50, 50);
}

.p > div {
  background-color: rgb(243, 255, 48);
}

.d > div {
  background-color: rgb(100, 100, 255);
}

.f > div > div {
  background-color: rgb(54, 200, 100);
}
