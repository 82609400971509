.person {
    display: flex;
    gap: 10px;
}

.avatar {
    width: 80px;
    height: 80px;
    margin-right: 10px;
    object-fit: cover;

    border-radius: 100%;
}

.person h1 {
    font-size: 1.5rem;
}

.link {
    margin-left: 10px;
    text-decoration: none;
    color: rgb(80, 80, 80);
}
