.asideNav {
  margin-top: 25px;
  margin-left: 15px;
  width: 100%;
  max-width: 800px;
  height: 100%;
  min-height: 200px;
  float:right;
  border-radius: 4px;
}

.card {
  background-color:#F9FAFF;
  border-radius: 4px;
  border: solid #4556B2 1px;
  margin-bottom: 3px;
}

.avatar {
  border: solid gray 2px;
}

.rating {
  float:right;
}

[rating ="1"] {
  border-color: red !important;
}

[rating ="2"] {
  border-color: orange !important;
}

[rating ="3"] {
  border-color: yellow !important;
}

[rating ="4"] {
  border-color: lawngreen !important;
}

[rating ="5"] {
  border-color: springgreen !important;
}
