.home {
  display: flex;
  flex-direction: column;
  background-color: #e1e4ff;
}

.main {
  width: 100%;
  height: 85%;
}

.planets {
  width: 100%;
}

.planets img {
  width: 100%;
}

.title {
  max-height: 80px;
}

.cards {
  padding-top: 32px;
  padding-bottom: 32px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 32px;

  background-color: #fff;
}

.footer {
  margin-top: 32px;
}

@media screen and (max-width: 768px) {
  .planets {
    overflow-x: hidden;
  }

  .planets img {
    height: 100px;
    width: auto;
  }
}
