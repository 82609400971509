.asideNav {
  margin-top: 30px;
  width: 300px;
  height: 320px;
  float:left;
  border-radius: 4px;
  border: solid #4556B2 1px;
  margin-bottom: 20px;
}

.center {
  margin: 10px;
  width: 30px;
}
